export const DeleteIcon = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.4736 10.918L5.08297 3.5274C4.76119 3.20506 4.23863 3.20506 3.91629 3.5274L3.5274 3.91629C3.20506 4.23863 3.20506 4.76119 3.5274 5.08297L10.918 12.4736C11.2398 12.796 11.7624 12.796 12.0847 12.4736L12.4736 12.0847C12.796 11.7624 12.796 11.2398 12.4736 10.918Z'
        fill='#D92D20'
      />
      <path
        d='M10.918 3.5274L3.5274 10.918C3.20506 11.2398 3.20506 11.7624 3.5274 12.0847L3.91629 12.4736C4.23863 12.796 4.76119 12.796 5.08297 12.4736L12.4736 5.08297C12.796 4.76119 12.796 4.23863 12.4736 3.91629L12.0847 3.5274C11.7624 3.20506 11.2398 3.20506 10.918 3.5274Z'
        fill='#D92D20'
      />
      <path
        d='M4.49991 3.28601C4.28868 3.28601 4.07746 3.36632 3.91629 3.52749L3.5274 3.91638C3.20506 4.23872 3.20506 4.76128 3.5274 5.08307L6.44493 8.0006L8.00051 6.44502L5.08297 3.52749C4.92236 3.36632 4.71113 3.28601 4.49991 3.28601Z'
        fill='#D92D20'
      />
      <path
        d='M8.00051 6.44495L6.44493 8.00052L3.5274 10.9181C3.20506 11.2398 3.20506 11.7624 3.5274 12.0847L3.91629 12.4736C4.07746 12.6348 4.28868 12.7157 4.49991 12.7157C4.71113 12.7157 4.92236 12.6348 5.08297 12.4736L8.00051 9.5561V6.44495Z'
        fill='#D92D20'
      />
    </svg>
  );
};
