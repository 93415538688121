export const DividerIcon = () => {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='20' cy='20' r='20' fill='#ECEDEF' />
      <path
        d='M18.5567 13.6287C18.4956 14.8228 17.5183 15.7518 16.3751 15.7022C15.1756 15.6498 14.2325 14.6564 14.2882 13.5059C14.3513 12.3144 15.332 11.3827 16.4731 11.431C17.6753 11.4861 18.6158 12.4775 18.5567 13.6287Z'
        fill='#596780'
      />
      <path
        d='M22.795 15.701C21.5908 15.6339 20.6571 14.6411 20.7249 13.4933C20.7994 12.2978 21.7814 11.3728 22.9192 11.4312C24.1274 11.4983 25.0571 12.4904 24.988 13.6396C24.9168 14.8391 23.9348 15.7614 22.795 15.701Z'
        fill='#596780'
      />
      <path
        d='M18.5599 19.9995C18.5575 20.5633 18.3334 21.1037 17.936 21.5038C17.5387 21.9039 16.9999 22.1317 16.4361 22.138C15.2694 22.1535 14.2881 21.1782 14.2881 20.0028C14.2907 19.4349 14.5182 18.8911 14.9208 18.4905C15.3233 18.0898 15.8681 17.8649 16.4361 17.8649C16.9992 17.8712 17.5374 18.0985 17.9346 18.4977C18.3319 18.897 18.5564 19.4363 18.5599 19.9995Z'
        fill='#596780'
      />
      <path
        d='M24.9919 20.0326C24.959 21.2146 23.9938 22.1584 22.8379 22.1383C21.6538 22.1174 20.6899 21.1314 20.7215 19.9701C20.7543 18.7901 21.7216 17.8443 22.8755 17.8651C24.0609 17.8859 25.0241 18.872 24.9919 20.0326Z'
        fill='#596780'
      />
      <path
        d='M18.5563 26.4965C18.4959 27.69 17.5185 28.619 16.3747 28.5693C15.1752 28.5177 14.2321 27.5242 14.2939 26.3737C14.3543 25.1822 15.335 24.2505 16.4761 24.3002C17.6743 24.3533 18.6154 25.3447 18.5563 26.4965Z'
        fill='#596780'
      />
      <path
        d='M24.9892 26.4979C24.9275 27.692 23.9495 28.6203 22.8063 28.5693C21.6061 28.5163 20.6644 27.5222 20.7255 26.3723C20.7872 25.1802 21.7686 24.2492 22.909 24.3009C24.1092 24.3546 25.049 25.3467 24.9892 26.4979Z'
        fill='#596780'
      />
    </svg>
  );
};
