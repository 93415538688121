export default class TableHelpers {
  static modelColumns = [
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 320,
    },
  ];

  static documentColumns = [
    {
      title: 'Item',
      dataIndex: 'item',
      key: 'item',
      width: 50,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 100,
    },
    {
      title: 'Tag',
      dataIndex: 'tag',
      key: 'tag',
      width: 80,
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      key: 'cost',
      width: 50,
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 40,
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      width: 50,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: 50,
    },
  ];
}
