export default class DataHelper {
  static signInFormFields = [
    {
      id: 'email',
      type: 'email',
      label: 'Email',
      placeholder: 'Enter your email',
    },
    {
      id: 'password',
      type: 'password',
      label: 'Password',
      placeholder: 'Enter your password',
    },
  ];
  static vendorModalFormFields = [
    {
      id: 'name',
      label: 'Name',
      placeholder: 'John',
    },
  ];
}
