import { useState } from 'react';
import { ShowToast } from '@common/components/ShowToast';
import { Button } from 'antd';
import { CirclePlus } from 'lucide-react';
import DataHelper from '../../../helpers/dataHelper';
import { CustomInputModal } from '@common/components';
import { useFormikForm } from '@common/hooks';
import { VendorModalValidationSchema } from '@common/validators';
import { apiEndpoints, queryKeys, usePostMutation } from '@services';
import { DEFAULT_ERROR_MESSAGE, ERROR, SUCCESS } from '@constants';

const initialValues = {
  name: '',
};

export const AddVendorModal = ({ headingText, textSecondary, btnText, className }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const formik = useFormikForm(initialValues, createVendorHandler, VendorModalValidationSchema);

  const { mutate, isPending } = usePostMutation(
    apiEndpoints.vendors.CREATE,
    onCreateVendorSuccess,
    error =>
      ShowToast(ERROR, error?.response?.data?.email?.[0] || error?.response?.data?.name?.[0] || DEFAULT_ERROR_MESSAGE),
    { queryKey: [queryKeys.VENDORS] }
  );

  function createVendorHandler() {
    mutate({ data: formik.values });
  }
  function onCreateVendorSuccess() {
    setIsModalOpen(false);
    formik.resetForm();
    ShowToast(SUCCESS, 'Vendor created successfully.');
  }

  const onCancel = () => {
    setIsModalOpen(false);
    formik.resetForm();
  };

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        onCancel={onCancel}
        className={`h-[2.375rem] bg-primary px-4 py-2.5 font-medium text-white ${className}`}
      >
        <CirclePlus className='h-4 w-4' />
        {btnText}
      </Button>
      <CustomInputModal
        formik={formik}
        handleClick={formik.handleSubmit}
        loading={isPending}
        formFields={DataHelper.vendorModalFormFields}
        open={isModalOpen}
        cancel={onCancel}
        headingText={headingText}
        textSecondary={textSecondary}
      />
    </>
  );
};
