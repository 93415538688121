import { Button, Modal } from 'antd';
import { createStyles } from 'antd-style';
import { AlertCircleIcon, AlertTriangleIcon, CheckCircleIcon, GeneratingAIIcon } from '@assets/svgs';

const useStyle = createStyles(() => ({
  'modal-footer': {
    marginTop: '32px !important',
    display: 'flex',
  },
  'modal-body': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  'modal-content': {
    padding: '24px !important',
    borderRadius: '24px !important',
  },
}));

export const CustomModal = ({
  open,
  cancel,
  heading,
  text,
  textClass,
  textPrimary,
  textSecondary,
  footerBtnClass,
  footerDangerBtnClass,
  SVG,
  secondBtnClick = () => null,
  firstBtnClick = () => null,
  loading = false,
}) => {
  const { styles } = useStyle();

  const classNames = {
    body: styles['modal-body'],
    footer: styles['modal-footer'],
    content: styles['modal-content'],
  };

  return (
    <Modal
      centered
      footer={[
        <Button key='back' onClick={firstBtnClick} className={`h-11 w-full ${footerBtnClass}`}>
          {textPrimary}
        </Button>,
        <Button
          type='primary'
          danger
          key='delete'
          onClick={secondBtnClick}
          disabled={loading}
          className={`ml-3 h-11 w-full text-white ${footerBtnClass} ${footerDangerBtnClass}`}
        >
          {loading ? 'Please wait...' : textSecondary}
        </Button>,
      ]}
      open={open}
      onCancel={cancel}
      width={400}
      closeIcon={null}
      classNames={classNames}
    >
      {SVG === 'AlertCircleIcon' ? (
        <AlertCircleIcon />
      ) : SVG === 'AlertTriangleIcon' ? (
        <AlertTriangleIcon />
      ) : SVG === 'CheckCircleIcon' ? (
        <CheckCircleIcon />
      ) : SVG === 'GeneratingAIIcon' ? (
        <GeneratingAIIcon />
      ) : (
        <></>
      )}
      <h3 className='mt-5 w-[16.25rem] text-center'>{heading}</h3>
      {text && (
        <p className={`w-[19.625rem] text-center text-sm font-normal leading-[1.375rem] ${textClass}`}>{text}</p>
      )}
    </Modal>
  );
};
