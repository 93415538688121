import { toast } from 'react-toastify';
import { ERROR, INFO, SUCCESS, WARNING } from '@constants';
import { ErrorIcon, InformationIcon, SuccessIcon, WarningIcon } from '@assets/svgs';
import 'react-toastify/dist/ReactToastify.css';

export const ShowToast = (status, message) => {
  let icon;
  let type;

  switch (status) {
  case SUCCESS:
    icon = <SuccessIcon />;
    type = 'success-toast';
    break;
  case ERROR:
    icon = <ErrorIcon />;
    type = 'error-toast';
    break;
  case INFO:
    icon = <InformationIcon />;
    type = 'information-toast';
    break;
  case WARNING:
    icon = <WarningIcon />;
    type = 'warning-toast';
    break;
  default:
    icon = null;
    type = null;
  }

  toast(
    <div className='flex items-center'>
      {icon}
      <span className='ml-2'>{message}</span>
    </div>,
    { className: type }
  );
};
