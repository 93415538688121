import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Popover } from 'antd';
import { Bell } from 'lucide-react';
import { Loader } from '.';
import { ShowToast } from './ShowToast';
import GenericHelpers from '../../helpers/genericHelpers';
import { ROUTES } from '@routes';
import { apiEndpoints, queryKeys, useUpdateMutation } from '@services';
import { AppContext } from '@useContext';
import { DEFAULT_ERROR_MESSAGE, ERROR } from '@constants';
import { NoNotifications, NotificationMessageBell } from '@assets/svgs';

export const Notifications = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { user, notifications, notificationsLoading } = useContext(AppContext);

  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const [localNotifications, setLocalNotifications] = useState([]);

  const { mutate: updateNotification } = useUpdateMutation(
    apiEndpoints.user.NOTIFICATIONS,
    () => GenericHelpers.invalidateQueries([queryKeys.NOTIFICATIONS, queryKeys.USER], queryClient),
    () => {
      ShowToast(ERROR, DEFAULT_ERROR_MESSAGE);
      setLocalNotifications(localNotifications);
    }
  );

  const handleClick = notification => {
    if (notification?.isNavigable) {
      setNotificationsVisible(false);
      navigate(ROUTES.PROCESSED_DOCUMENTS);
    }

    if (!notification?.isRead) {
      const updatedNotifications = localNotifications.map(n => (n.id === notification.id ? { ...n, isRead: true } : n));
      setLocalNotifications(updatedNotifications);

      updateNotification({
        data: { isRead: true },
        id: `${notification?.id}/update`,
      });
    }
  };

  useEffect(() => {
    if (notifications) setLocalNotifications(notifications?.data?.results || []);
  }, [notifications]);

  const notificationsContent = (
    <div className='absolute -right-10 top-0 h-[29.25rem] w-[22.8125rem] overflow-hidden rounded-xl bg-white shadow-popoverShadow'>
      <div className='border-paleGray border-paleGray h-[3.5rem]'>
        <h1 className='p-4 text-base font-bold leading-6'>Notifications</h1>
      </div>

      <div className='custom-scrollbar my-3 mr-2 flex h-[27.25rem] flex-col items-center gap-2 overflow-y-scroll bg-local pl-4 pr-2 last:pb-12'>
        {notificationsLoading ? (
          <div className='mt-[6.5rem]'>
            <Loader />
          </div>
        ) : localNotifications.length > 0 ? (
          localNotifications.map(notification => (
            <div
              key={notification?.id}
              className={`flex w-full gap-2 rounded-lg bg-notificationsBg ${
                !notification?.isRead && 'border border-notificationsOutline'
              } cursor-pointer p-2`}
              onClick={() => handleClick(notification)}
            >
              <NotificationMessageBell />
              <div className='flex flex-col gap-1'>
                <h1 className='text-xs font-semibold leading-[0.875rem] text-dimGray'>{notification?.message}</h1>
                <p className='text-[0.5rem] font-normal italic leading-[0.625rem] text-dustyBlue'>
                  {GenericHelpers.formatTimestamp(notification?.modified)}
                </p>
              </div>
            </div>
          ))
        ) : (
          <div className='mt-[6.5rem] flex flex-col justify-center gap-5'>
            <NoNotifications />
            <h3 className='text-lg font-medium leading-[22px] text-[#5E5E5E]'>No Notifications</h3>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <Popover
      trigger='click'
      placement='bottom'
      content={notificationsContent}
      open={notificationsVisible}
      onOpenChange={setNotificationsVisible}
    >
      <div
        className='relative flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-[#D0D5DD]'
        onClick={() => {
          !notificationsVisible && queryClient.invalidateQueries({ queryKey: [queryKeys.NOTIFICATIONS] });
          setNotificationsVisible(!notificationsVisible);
        }}
      >
        {user?.hasNotifications && <div className='absolute right-0 top-0 h-2 w-2 rounded-full bg-bloodRed' />}
        <Bell color='#1565D8' className='h-4 w-4' />
      </div>
    </Popover>
  );
};
