import { Select } from 'antd';
import { ShowErrorMessage } from './ShowErrorMessage';

export const CustomSelect = ({ setSelected, options, hasError = false, message, isLoading = false }) => {
  const handleChange = async value => {
    const selectedObject = options.find(opt => opt.value === value);
    await setSelected(selectedObject);
  };
  return (
    <div className='flex-row'>
      <Select
        showSearch
        className='h-10 w-[19.75rem]'
        placeholder='Search to Select'
        optionFilterProp='label'
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
        }
        options={options}
        onChange={handleChange}
        loading={isLoading}
        disabled={isLoading}
      />
      <ShowErrorMessage className='mt-1' hasError={hasError} message={message} />
    </div>
  );
};
