import { RouterProvider } from 'react-router-dom';
import { router } from '@routes';
import { useSentry, useSetupAxios } from '@services';
import { AppProvider } from '@useContext';

function App() {
  useSetupAxios();
  useSentry();

  return (
    <AppProvider>
      <RouterProvider router={router} />
    </AppProvider>
  );
}

export default App;
