export const GeneratingAIIcon = () => {
  return (
    <svg width='57' height='51' viewBox='0 0 57 51' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M22.8 5.66666L15.675 21.25L0 28.3333L15.675 35.4167L22.8 51L29.925 35.4167L45.6 28.3333L29.925 21.25L22.8 5.66666ZM45.6 0L42.0375 7.79166L34.2 11.3333L42.0375 14.875L45.6 22.6667L49.1625 14.875L57 11.3333L49.1625 7.79166L45.6 0Z'
        fill='url(#paint0_linear_1388_22971)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1388_22971'
          x1='-14.3739'
          y1='93.075'
          x2='59.6178'
          y2='90.3062'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5A99BD' />
          <stop offset='1' stopColor='#1565D8' />
        </linearGradient>
      </defs>
    </svg>
  );
};
