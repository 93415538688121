import { useState } from 'react';
import { ShowToast } from '@common/components/ShowToast';
import { CreateDocumentValidationSchema } from '@common/validators/VendorsValidationSchema';
import { Table } from 'antd';
import { DocumentsAction } from './components/DocumentsAction';
import GenericHelpers from '../../helpers/genericHelpers';
import { DocumentDrawer, Loader } from '@common/components';
import { useFormikForm } from '@common/hooks';
import { apiEndpoints, queryKeys, useGetQuery, useUpdateMutation } from '@services';
import { DEFAULT_ERROR_MESSAGE, ENTER, ERROR, PROCESSED_DOCUMENT_COLUMNS, SUCCESS } from '@constants';

const initialValues = {
  id: null,
  file: null,
  documentTrainingTableData: [],
  isEditMode: false,
  totalCost: null,
  totalAmount: null,
  markupValue: null,
};

const ProcessedDocument = () => {
  const [open, setOpen] = useState(false);
  const formik = useFormikForm(initialValues, handleSubmit, CreateDocumentValidationSchema);
  const { isEditMode } = formik.values;

  const { data: ProcessedDocuments, isFetching: processedDocumentFetching } = useGetQuery(
    [queryKeys.PROCESSED_DOCUMENTS],
    apiEndpoints.ProcessedDocuments.LISTING
  );

  const { mutate: pushToSquidErp, isPending: pushToSquidErpPending } = useUpdateMutation(
    apiEndpoints.vendors.CREATE_DOCUMENT, // TODO: ENDPOINT NEED TO UPDATE ONCE DONE FROM BACKEND
    () => ShowToast(SUCCESS, 'Successfully pushed to squid erp.'),
    () => ShowToast(ERROR, DEFAULT_ERROR_MESSAGE)
  );

  const { mutate: updateDocument, isPending: updateDocumentPending } = useUpdateMutation(
    apiEndpoints.vendors.CREATE_DOCUMENT,
    () => ShowToast(SUCCESS, 'Document updated successfully.'),
    () => ShowToast(ERROR, DEFAULT_ERROR_MESSAGE),
    { queryKey: [queryKeys.PROCESSED_DOCUMENTS] }
  );

  const vendorData =
    ProcessedDocuments?.data?.results?.map(document => ({
      key: document.id,
      Timestamp: GenericHelpers.formatTimestamp(document.created),
      Vendor: <p className='pl-4'>{document.vendor.name}</p>,
      InputFile: <p className='pl-4'>{document.name}</p>,
      Action: <DocumentsAction key={document.id} document={document} formik={formik} setOpen={setOpen} />,
    })) || [];

  function handleSubmit() {
    if (!isEditMode) {
      formik.setFieldValue('isEditMode', true);
    } else {
      pushToSquidErp({
        id: formik.values?.file?.id,
        data: { documentData: formik.values?.documentTrainingTableData },
      });
    }
  }

  const calculateCosts = value => {
    if (value === '' || value === null) return;

    let totalCost = 0;
    let totalAmount = 0;

    const updatedData = formik.values.documentTrainingTableData?.map(data => {
      const rate = parseFloat((data.rate || '0').toString().replace(/,/g, ''));
      const amount = parseFloat((data.amount || '0').toString().replace(/,/g, ''));
      const originalCost = rate / (1 + value / 100);

      totalCost += originalCost;
      totalAmount += amount;

      return { ...data, cost: parseFloat(originalCost.toFixed(2)) };
    });

    formik.setFieldValue('id', `${new Date()}`);
    formik.setFieldValue('documentTrainingTableData', updatedData);
    formik.setFieldValue('totalCost', totalCost.toFixed(2));
    formik.setFieldValue('totalAmount', totalAmount.toFixed(2));
  };

  return (
    <>
      <h3 className='px-6 py-8'>Document List</h3>
      {processedDocumentFetching ? (
        <div className='flex h-[calc(100vh-180px)] items-center justify-center'>
          <Loader />
        </div>
      ) : (
        <Table
          sticky
          className='h-[calc(100vh-180px)] overflow-y-auto'
          pagination={false}
          columns={PROCESSED_DOCUMENT_COLUMNS}
          dataSource={vendorData}
        />
      )}
      <DocumentDrawer
        title={isEditMode ? 'Edit Document' : 'View Document'}
        open={open}
        onClose={() => {
          setOpen(false);
          formik.clearForm();
        }}
        formik={formik}
        isReadOnly={!isEditMode}
        showReUploadButton={false}
        btnText={isEditMode ? 'Upload to Squid' : 'Edit'}
        optionalBtnText={isEditMode ? 'update' : null}
        showCancelBtn={!!isEditMode}
        optionalBtnHandler={() => {
          formik.errors.documentTrainingTableData
            ? formik.setFieldError('documentTrainingTableData', formik.errors.documentTrainingTableData)
            : updateDocument({
              data: {
                documentData: formik.values?.documentTrainingTableData,
                totalCost: formik.values?.totalCost,
                totalAmount: formik.values?.totalAmount,
                markup: formik.values?.markupValue,
              },
              id: formik.values?.file?.id,
            });
        }}
        disable={pushToSquidErpPending || updateDocumentPending}
        className={!isEditMode ? '' : 'pointer-events-none'} // TODO: for demo purpose only
        onSearchKeyDown={e => e.key === ENTER && calculateCosts(e.target.value)}
      />
    </>
  );
};

export default ProcessedDocument;
