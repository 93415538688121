import { UpdateVendorModal } from '.';
import { DeleteModal } from '@common/components';
import { apiEndpoints, queryKeys } from '@services';

export const VendorAction = ({ vendor }) => {
  return (
    <div className='flex items-center gap-3 pl-4'>
      <UpdateVendorModal
        headingText='Edit Vendor'
        textSecondary='Update'
        className='h-min border-none !bg-white !p-0'
        vendor={vendor}
      />
      <DeleteModal
        id={vendor.id}
        element='Vendor'
        item='Vendor'
        apiUrl={apiEndpoints.vendors.DELETE}
        deleteSuccessMessage={'Vendor deleted successfully.'}
        queryKey={[queryKeys.VENDORS]}
      />
    </div>
  );
};
