import { Input, Button, Modal } from 'antd';
import { createStyles } from 'antd-style';

const useStyle = createStyles(() => ({
  'modal-footer': {
    marginTop: '32px !important',
    display: 'flex',
  },
  'modal-body': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '24px',
  },
  'modal-content': {
    padding: '40px !important',
    borderRadius: '24px !important',
  },
}));

export const CustomInputModal = ({
  formik,
  open,
  cancel,
  headingText,
  textSecondary,
  handleClick,
  loading,
  formFields,
}) => {
  const { styles } = useStyle();

  const classNames = {
    body: styles['modal-body'],
    footer: styles['modal-footer'],
    content: styles['modal-content'],
  };

  return (
    <Modal
      centered
      open={open}
      onCancel={cancel}
      footer={[
        <Button key='back' onClick={cancel} className='h-11 w-1/2'>
          Cancel
        </Button>,
        <Button type='primary' key='submit' onClick={handleClick} className='ml-3 h-11 w-1/2'>
          {loading ? 'Please wait...' : textSecondary}
        </Button>,
      ]}
      width={448}
      closeIcon={null}
      classNames={classNames}
    >
      <h2>{headingText}</h2>
      <div className='flex w-full flex-col gap-4'>
        {formFields.map(({ id, label, placeholder }) => (
          <div key={id} className='flex flex-col gap-1.5'>
            <label htmlFor={id}>{label}</label>
            <Input
              id={id}
              className='h-10'
              placeholder={placeholder}
              value={formik?.values[id]}
              onChange={formik?.handleChange}
            />
            <div className='mt-1 text-xs text-warning'>{formik?.errors[id]}</div>
          </div>
        ))}
      </div>
    </Modal>
  );
};
