export const ErrorIcon = () => {
  return (
    <svg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.41839 15.9999C9.4182 15.9999 9.41846 15.9999 9.41827 15.9999C6.90712 15.9999 4.79431 15.8821 3.14817 15.7377C2.13348 15.6487 1.49814 14.5983 1.88715 13.6569C2.96619 11.0455 4.29841 8.4659 5.86125 5.96405C6.92505 4.26106 7.952 2.82566 8.82042 1.69631C9.42606 0.908681 10.6109 0.908454 11.2166 1.69604C12.0807 2.81976 13.1022 4.24703 14.1607 5.93954C15.7155 8.42569 17.0428 10.9891 18.12 13.5844C18.5111 14.5266 17.8733 15.5786 16.8571 15.6679C14.3476 15.8885 11.8537 15.9999 9.41839 15.9999Z'
        fill='#DD636E'
      />
      <path
        d='M10.3734 1.14757C10.0787 1.22504 9.80287 1.40983 9.5989 1.70151C8.80224 2.84084 7.86024 4.28911 6.88447 6.00723C5.45076 8.53129 4.22863 11.1338 3.23897 13.7681C2.91548 14.6286 3.3638 15.5796 4.14355 15.8173C3.79491 15.7921 3.46313 15.7653 3.14822 15.7378C2.13337 15.6487 1.4983 14.5985 1.88705 13.6568C2.96613 11.0457 4.29853 8.46607 5.86137 5.96419C6.92521 4.26131 7.95201 2.8256 8.82051 1.69655C9.20388 1.19788 9.81977 1.0148 10.3734 1.14757Z'
        fill='#DA4A54'
      />
      <path
        d='M17.0298 8.60742V8.49795C17.0298 8.11182 17.3288 7.79162 17.714 7.76518L18.899 7.68389C19.3235 7.65476 19.6838 7.99123 19.6838 8.41666V8.68871C19.6838 9.11415 19.3234 9.45062 18.899 9.42149L17.714 9.3402C17.3288 9.3138 17.0298 8.9936 17.0298 8.60742Z'
        fill='#DD636E'
      />
      <path
        d='M16.1151 5.08541L16.0604 4.99057C15.8673 4.65615 15.9662 4.22935 16.2866 4.01385L17.2721 3.35098C17.6252 3.11354 18.1054 3.22475 18.3181 3.59318L18.4542 3.82881C18.6669 4.19724 18.5231 4.66878 18.1409 4.8558L17.0741 5.37788C16.7272 5.54765 16.3082 5.41983 16.1151 5.08541Z'
        fill='#DD636E'
      />
      <path
        d='M13.5623 2.49235L13.4675 2.43761C13.133 2.24455 13.0053 1.8255 13.175 1.47867L13.6971 0.411811C13.8841 0.0296873 14.3556 -0.114129 14.7241 0.0985694L14.9597 0.234594C15.3281 0.447331 15.4393 0.927577 15.2019 1.28061L14.539 2.26618C14.3235 2.58658 13.8967 2.68542 13.5623 2.49235Z'
        fill='#DD636E'
      />
      <path
        d='M2.9704 8.60742V8.49795C2.9704 8.11182 2.67141 7.79162 2.28615 7.76518L1.10116 7.68389C0.676742 7.65476 0.316406 7.99123 0.316406 8.41666V8.68871C0.316406 9.11415 0.676742 9.45062 1.10116 9.42149L2.28615 9.3402C2.67138 9.3138 2.9704 8.9936 2.9704 8.60742Z'
        fill='#DD636E'
      />
      <path
        d='M3.8849 5.08541L3.93963 4.99057C4.1327 4.65615 4.03386 4.22935 3.71347 4.01385L2.72789 3.35098C2.37486 3.11354 1.89461 3.22475 1.68188 3.59318L1.54585 3.82881C1.33312 4.19724 1.47697 4.66878 1.85909 4.8558L2.92595 5.37788C3.27279 5.54765 3.69183 5.41983 3.8849 5.08541Z'
        fill='#DD636E'
      />
      <path
        d='M6.4376 2.49235L6.53243 2.43761C6.86685 2.24455 6.99463 1.8255 6.8249 1.47867L6.30282 0.411811C6.11581 0.0296873 5.64426 -0.114129 5.27583 0.0985694L5.04021 0.234594C4.67178 0.447331 4.56057 0.927577 4.798 1.28061L5.46088 2.26618C5.67637 2.58658 6.10317 2.68542 6.4376 2.49235Z'
        fill='#DD636E'
      />
      <path
        d='M9.58001 5.1297C9.86257 5.1013 10.1451 5.1013 10.4277 5.1297C10.7852 5.16568 11.0589 5.46424 11.0721 5.82329C11.1152 6.99777 11.1152 8.17225 11.0721 9.34673C11.0589 9.70578 10.7852 10.0044 10.4277 10.0403C10.1451 10.0687 9.86257 10.0687 9.58001 10.0403C9.22251 10.0043 8.94884 9.70578 8.93563 9.34673C8.89255 8.17225 8.89255 6.99777 8.93563 5.82329C8.94884 5.4642 9.22251 5.16564 9.58001 5.1297Z'
        fill='white'
      />
      <path
        d='M9.60047 11.3292C9.86938 11.3164 10.1383 11.3164 10.4072 11.3292C10.7778 11.347 11.0754 11.6453 11.0921 12.0159C11.1084 12.3772 11.1084 12.7385 11.0921 13.0999C11.0754 13.4705 10.7778 13.7688 10.4072 13.7866C10.1383 13.7994 9.86938 13.7994 9.60047 13.7866C9.22989 13.7688 8.93227 13.4705 8.91555 13.0999C8.89924 12.7386 8.89924 12.3772 8.91555 12.0159C8.93227 11.6453 9.22989 11.347 9.60047 11.3292Z'
        fill='white'
      />
    </svg>
  );
};
