export const InformationIcon = ({ props }) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z'
        fill='#2196F3'
      />
      <path
        d='M12 9C12.6904 9 13.25 8.44036 13.25 7.75C13.25 7.05964 12.6904 6.5 12 6.5C11.3096 6.5 10.75 7.05964 10.75 7.75C10.75 8.44036 11.3096 9 12 9Z'
        fill='white'
      />
      <path d='M11 10.5H13V17.5H11V10.5Z' fill='white' />
    </svg>
  );
};
