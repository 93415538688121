import { useState } from 'react';
import { ShowToast } from '@common/components/ShowToast';
import { Button } from 'antd';
import DataHelper from '../../../helpers/dataHelper';
import { CustomInputModal } from '@common/components';
import { useFormikForm } from '@common/hooks';
import { VendorModalValidationSchema } from '@common/validators';
import { apiEndpoints, queryKeys, useUpdateMutation } from '@services';
import { DEFAULT_ERROR_MESSAGE, ERROR, SUCCESS } from '@constants';
import { EditFilled } from '@assets/svgs';

const initialValues = vendor => ({
  name: vendor.name || '',
});

export const UpdateVendorModal = ({ headingText, textSecondary, btnText, className, vendor }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const formik = useFormikForm(initialValues(vendor), updateVendorHandler, VendorModalValidationSchema);

  const { mutate, isPending } = useUpdateMutation(
    apiEndpoints.vendors.UPDATE,
    onUpdateVendorSuccess,
    error =>
      ShowToast(ERROR, error?.response?.data?.email?.[0] || error?.response?.data?.name?.[0] || DEFAULT_ERROR_MESSAGE),
    { queryKey: [queryKeys.VENDORS] }
  );

  function updateVendorHandler() {
    mutate({ data: formik.values, id: vendor.id });
  }
  function onUpdateVendorSuccess() {
    setIsModalOpen(false);
    ShowToast(SUCCESS, 'Vendor updated successfully.');
  }

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        onCancel={() => setIsModalOpen(false)}
        className={`h-[2.375rem] bg-primary px-4 py-2.5 font-medium text-white ${className}`}
      >
        <EditFilled className='h-5 w-5' />
        {btnText}
      </Button>
      <CustomInputModal
        formik={formik}
        handleClick={formik.handleSubmit}
        loading={isPending}
        formFields={DataHelper.vendorModalFormFields}
        open={isModalOpen}
        cancel={() => setIsModalOpen(false)}
        headingText={headingText}
        textSecondary={textSecondary}
      />
    </>
  );
};
