import { useRef } from 'react';
import { Button } from 'antd';
import { ShowToast } from './ShowToast';
import { apiEndpoints, queryKeys, useUpdateMutation } from '@services';
import { DEFAULT_ERROR_MESSAGE, ERROR, SUCCESS } from '@constants';

export const ReUploadFile = ({ formik }) => {
  const fileInputRef = useRef(null);

  const { mutate: updateDocument, isPending } = useUpdateMutation(
    apiEndpoints.vendors.CREATE_DOCUMENT,
    res => {
      ShowToast(SUCCESS, 'File re-uploaded successfully.');
      formik.setFieldValue('file', {
        id: res.data.id,
        document: res.data.document,
      });
    },
    error => ShowToast(ERROR, error?.response?.data?.document || DEFAULT_ERROR_MESSAGE),
    { queryKey: [queryKeys.VENDOR_DOCUMENTS, formik.values?.selectedVendor?.value] }
  );

  const handleReUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async e => {
    const file = e.target.files[0];
    const data = new FormData();
    data.append('document', file);
    updateDocument({ data, id: formik.values?.file?.id });
  };

  return (
    <>
      {formik.values.file !== null && (
        <>
          <Button disabled={isPending} className='border-primary text-primary' onClick={handleReUploadClick}>
            {isPending ? 'Uploading...' : 'Re-Upload File'}
          </Button>
          <input type='file' ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
        </>
      )}
    </>
  );
};
