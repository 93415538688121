import { ROUTES } from '@routes';

export default class ModalHelpers {
  static getUploadNewModalProps = (formik, setIsModalOpen, navigate) => {
    if (formik.values.selectedVendor?.documentCount < 10) {
      return {
        textPrimary: 'Go to Document Setup',
        heading: 'Insufficient Samples',
        text: 'Each vendor requires at least 10 samples. Please add more and try again!',
        SVG: 'AlertTriangleIcon',
        firstBtnClick: () => navigate(ROUTES.DOCUMENT_SETUP),
      };
    } else if (!formik.values.selectedVendor?.trained && !formik.values.selectedVendor?.processing) {
      return {
        textPrimary: 'Go to Document Setup',
        heading: 'Model not trained',
        text: 'Model not trained. Please train the model first and try again.',
        SVG: 'AlertTriangleIcon',
        firstBtnClick: () => navigate(`${ROUTES.DOCUMENT_SETUP}?vendorId=${formik.values.selectedVendor?.value}`),
      };
    } else if (formik.values.selectedVendor?.processing) {
      return {
        textPrimary: 'Close',
        heading: 'Training is underway. Try again later.',
        SVG: 'AlertTriangleIcon',
        firstBtnClick: () => setIsModalOpen(false),
      };
    } else if (formik.values.showGeneratingAIModal) {
      return {
        textPrimary: 'Ok',
        heading: 'Generating AI content',
        text: 'We’re generating your output using AI. You’ll receive a notification once it’s ready. Please check the ‘Processed Documents’ tab for the final result.',
        SVG: 'GeneratingAIIcon',
        firstBtnClick: () => {
          setIsModalOpen(false);
          formik.setFieldValue('showGeneratingAIModal', false);
        },
      };
    }
  };
}
