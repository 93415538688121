export const SuccessIcon = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 0C4.48438 0 0 4.48438 0 10C0 15.5156 4.48438 20 10 20C15.5156 20 20 15.5156 20 10C20 4.48438 15.5156 0 10 0Z'
        fill='#4BAE4F'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.8359 6.62891C15.0781 6.87109 15.0781 7.26953 14.8359 7.51172L8.97656 13.3711C8.85547 13.4922 8.69531 13.5547 8.53516 13.5547C8.375 13.5547 8.21484 13.4922 8.09375 13.3711L5.16406 10.4414C4.92188 10.1992 4.92188 9.80078 5.16406 9.55859C5.40625 9.31641 5.80469 9.31641 6.04688 9.55859L8.53516 12.0469L13.9531 6.62891C14.1953 6.38281 14.5937 6.38281 14.8359 6.62891Z'
        fill='white'
      />
    </svg>
  );
};
