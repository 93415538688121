export const NotificationMessageBell = () => {
  return (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8.00903 16.0001H7.99709C6.94999 16.0001 6.09814 15.1482 6.09814 14.1011V13.6573C6.09814 13.3985 6.30801 13.1886 6.56688 13.1886H9.43923C9.6981 13.1886 9.90797 13.3985 9.90797 13.6573V14.1011C9.90797 15.1482 9.05609 16.0001 8.00903 16.0001Z'
        fill='#FFA81C'
      />
      <path
        d='M9.43935 13.1886H9.40892C8.63255 13.1886 8.00317 13.818 8.00317 14.5943V15.9941C8.00317 15.9974 8.00585 16.0001 8.00914 16.0001C9.05624 16.0001 9.90809 15.1482 9.90809 14.1011V13.6573C9.90809 13.3984 9.69822 13.1886 9.43935 13.1886Z'
        fill='#FF9300'
      />
      <path
        d='M8.94012 2.92979H7.06615C6.80728 2.92979 6.59741 2.71992 6.59741 2.46105V1.40597C6.59741 0.630828 7.22802 0.000244141 8.00312 0.000244141C8.77822 0.000244141 9.40883 0.630859 9.40883 1.40597V2.46105C9.40886 2.71992 9.19899 2.92979 8.94012 2.92979Z'
        fill='#FFA81C'
      />
      <path
        d='M8.94015 2.92979C9.19901 2.92979 9.40888 2.71992 9.40888 2.46105V1.40597C9.40888 1.1477 9.33887 0.905471 9.21683 0.697273C8.80775 -0.000639689 8.00317 0.656045 8.00317 1.46502V1.99282C8.00317 2.51029 8.42267 2.92979 8.94015 2.92979Z'
        fill='#FF9300'
      />
      <path
        d='M12.9701 14.126H3.03608C2.63038 14.126 2.30029 13.7959 2.30029 13.3902V12.72C2.30029 11.986 2.57057 11.281 3.06133 10.7351C3.39714 10.3616 3.58207 9.87922 3.58207 9.37694V6.34021C3.58207 3.90244 5.56529 1.91919 8.00303 1.91919C10.4408 1.91919 12.424 3.90244 12.424 6.34021V9.37694C12.424 9.87922 12.6089 10.3616 12.9447 10.7351C13.4355 11.281 13.7058 11.9859 13.7058 12.72V13.3902C13.7058 13.7959 13.3758 14.126 12.9701 14.126Z'
        fill='#FFCF2C'
      />
      <path
        d='M12.9702 14.126C13.3759 14.126 13.7059 13.7959 13.7059 13.3902V12.72C13.7059 11.9859 13.4357 11.281 12.9449 10.7351C12.6091 10.3616 12.4241 9.87922 12.4241 9.37694V6.34021C12.4241 6.05486 12.397 5.77573 12.3451 5.50529C11.7098 2.19487 8.00317 4.65177 8.00317 8.02259V9.15901C8.00317 11.9022 10.227 14.126 12.9702 14.126Z'
        fill='#FFC12E'
      />
      <path
        d='M2.30054 12.72V13.3902C2.30054 13.7959 2.63062 14.126 3.03633 14.126H12.9703C13.376 14.126 13.7061 13.7959 13.7061 13.3902V12.72C13.7061 12.151 13.1707 11.7832 12.6017 11.7832H3.40494C2.8359 11.7832 2.30054 12.151 2.30054 12.72Z'
        fill='#FFF566'
      />
      <path
        d='M8.00317 11.7832C8.00317 13.0771 9.05206 14.126 10.3459 14.126H12.9702C13.3759 14.126 13.7059 13.7959 13.7059 13.3902V12.72C13.7059 12.151 13.1706 11.7832 12.6015 11.7832H8.00321C8.00319 11.7832 8.00317 11.7832 8.00317 11.7832Z'
        fill='#FFE645'
      />
      <path
        d='M0.469294 5.17702C0.436514 5.17702 0.403265 5.17358 0.369922 5.16636C0.116867 5.11174 -0.0439717 4.8623 0.0106517 4.60925C0.386016 2.87055 1.34396 1.27336 2.70798 0.11185C2.9051 -0.055991 3.20093 -0.0322102 3.36874 0.164849C3.53658 0.361971 3.51286 0.657778 3.31574 0.825619C2.10753 1.85445 1.25918 3.26842 0.927 4.80709C0.879595 5.0268 0.685288 5.17702 0.469294 5.17702Z'
        fill='#FF8B6E'
      />
      <path
        d='M15.5308 5.17723C15.3148 5.17723 15.1205 5.02705 15.0731 4.80733C14.7409 3.26867 13.8926 1.85469 12.6844 0.825897C12.4873 0.658056 12.4636 0.362217 12.6314 0.165127C12.7992 -0.031995 13.095 -0.0557446 13.2922 0.112128C14.6562 1.27358 15.6141 2.8708 15.9895 4.60949C16.0441 4.86255 15.8832 5.11198 15.6302 5.16661C15.5969 5.17376 15.5636 5.17723 15.5308 5.17723Z'
        fill='#FF674F'
      />
      <path
        d='M2.27157 5.72544C2.24285 5.72544 2.21373 5.72278 2.18448 5.71728C1.93005 5.66947 1.76258 5.42444 1.8104 5.17004C2.06767 3.801 2.78915 2.53639 3.84187 1.60919C4.03611 1.43804 4.33232 1.45688 4.50341 1.65113C4.67453 1.84541 4.65572 2.14159 4.46147 2.31268C3.56491 3.10239 2.95058 4.17862 2.73171 5.34316C2.6894 5.56838 2.49263 5.72544 2.27157 5.72544Z'
        fill='#FF8B6E'
      />
      <path
        d='M13.7286 5.72562C13.5076 5.72562 13.3108 5.56856 13.2685 5.34338C13.0496 4.17887 12.4354 3.1026 11.5388 2.31293C11.3445 2.14181 11.3258 1.84562 11.4969 1.65135C11.668 1.4571 11.9642 1.43832 12.1584 1.60941C13.2111 2.53661 13.9325 3.80119 14.1898 5.1702C14.2376 5.42463 14.0701 5.66962 13.8157 5.71744C13.7865 5.72297 13.7574 5.72562 13.7286 5.72562Z'
        fill='#FF674F'
      />
    </svg>
  );
};
