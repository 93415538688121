import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { performDeleteRequest, performGetRequest, performPatchRequest, performPostRequest } from './apiClient';

export const useGetQuery = (queryKey, url, params = {}, options = {}) => {
  const requestOptions = {
    refetchOnWindowFocus: false,
    retry: false,
    ...options,
  };
  return useQuery({
    queryKey,
    queryFn: () => {
      return performGetRequest(url, params);
    },
    ...requestOptions,
  });
};

export const usePostMutation = (apiUrl, onSuccess, onError, queryKey) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data, id, params }) => {
      const url = id ? `${apiUrl}${id}/` : apiUrl;
      return performPostRequest(url, data, params);
    },
    onSuccess,
    onError,
    onSettled: (data, error) => {
      if (error) return;
      if (queryKey) {
        queryClient.invalidateQueries(queryKey);
      }
    },
  });
};

export const useUpdateMutation = (apiUrl, onSuccess, onError, queryKey) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data, id, params }) => {
      const url = id ? `${apiUrl}${id}/` : apiUrl;
      return performPatchRequest(url, data, params);
    },
    onSuccess,
    onError,
    onSettled: (data, error) => {
      if (error) return;
      if (queryKey) {
        queryClient.invalidateQueries(queryKey);
      }
    },
  });
};

export const useDeleteMutation = (apiUrl, onSuccess, onError, queryKey) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data, id, params }) => {
      const url = id ? `${apiUrl}${id}/` : apiUrl;
      return performDeleteRequest(url, data, params);
    },
    onSuccess,
    onError,
    onSettled: (data, error) => {
      if (error) return;
      if (queryKey) {
        queryClient.invalidateQueries(queryKey);
      }
    },
  });
};

export const useUploadFileMutation = (url, onSuccess, onError, setUploadedFile) => {
  return useMutation({
    mutationFn: file => {
      return axios.post(`${import.meta.env.VITE_BACKEND_BASE_URL}${url}`, file, {
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress: event => {
          const progress = Math.round((100 * event.loaded) / event.total);
          setUploadedFile({
            url: null,
            id: null,
            status: 'pending',
            progress: progress,
            loading: true,
          });
        },
      });
    },
    onSuccess,
    onError,
  });
};
