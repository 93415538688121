import { SignInImage } from '@assets/svgs';

export const SignInImg = () => {
  return (
    <div className='col-span-6 col-start-7 h-screen bg-aliceBlue px-[62px]'>
      <div className='flex h-full w-[36.5rem] items-center justify-center'>
        <SignInImage className='h-auto w-full' />
      </div>
    </div>
  );
};
