export const NoNotifications = () => {
  return (
    <svg width='144' height='101' viewBox='0 0 144 101' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M115.532 0H31.2766C27.3989 0 24.2554 3.13401 24.2554 7V91C24.2554 94.866 27.3989 98 31.2766 98H115.532C119.41 98 122.553 94.866 122.553 91V7C122.553 3.13401 119.41 0 115.532 0Z'
        fill='#E8F0FC'
      />
      <g filter='url(#filter0_d_1435_6880)'>
        <path
          d='M104.298 7H9.51088C7.57201 7 6.00024 8.567 6.00024 10.5V28C6.00024 29.933 7.57201 31.5 9.51088 31.5H104.298C106.237 31.5 107.809 29.933 107.809 28V10.5C107.809 8.567 106.237 7 104.298 7Z'
          fill='white'
        />
      </g>
      <path
        d='M54.4469 12.5999H36.1916C35.0283 12.5999 34.0852 13.5401 34.0852 14.6999C34.0852 15.8597 35.0283 16.7999 36.1916 16.7999H54.4469C55.6102 16.7999 56.5533 15.8597 56.5533 14.6999C56.5533 13.5401 55.6102 12.5999 54.4469 12.5999Z'
        fill='#C9DFFF'
      />
      <path
        d='M67.0853 21.7H36.1917C35.0284 21.7 34.0853 22.6402 34.0853 23.8C34.0853 24.9597 35.0284 25.9 36.1917 25.9H67.0853C68.2486 25.9 69.1917 24.9597 69.1917 23.8C69.1917 22.6402 68.2486 21.7 67.0853 21.7Z'
        fill='#EEF5FF'
      />
      <path
        d='M24.9577 9.1001H11.6173C9.67845 9.1001 8.10669 10.6671 8.10669 12.6001V25.9001C8.10669 27.8331 9.67845 29.4001 11.6173 29.4001H24.9577C26.8966 29.4001 28.4684 27.8331 28.4684 25.9001V12.6001C28.4684 10.6671 26.8966 9.1001 24.9577 9.1001Z'
        fill='#1565D8'
      />
      <path
        d='M16.6882 21.5458H18.0924L18.1079 21.0068C18.1079 20.2984 18.5558 20.1136 19.1891 19.9134C19.8244 19.7332 20.3836 19.3518 20.7821 18.8266C21.1807 18.3015 21.3969 17.6613 21.398 17.0028C21.4119 16.6008 21.342 16.2002 21.1926 15.8265C21.0432 15.4528 20.8176 15.1141 20.5301 14.8319C20.2426 14.5496 19.8995 14.3299 19.5224 14.1867C19.1454 14.0434 18.7426 13.9798 18.3396 13.9998C17.9137 13.9906 17.4903 14.0676 17.0952 14.2264C16.7001 14.3852 16.3414 14.6223 16.041 14.9234C15.7405 15.2245 15.5046 15.5833 15.3474 15.978C15.1902 16.3728 15.1151 16.7953 15.1266 17.2198H16.5786C16.5731 16.9854 16.6146 16.7522 16.7006 16.534C16.7867 16.3158 16.9155 16.1169 17.0797 15.9489C17.2438 15.781 17.4399 15.6474 17.6565 15.556C17.8731 15.4646 18.1058 15.4172 18.341 15.4166C18.5531 15.4044 18.7653 15.4378 18.9633 15.5146C19.1613 15.5914 19.3404 15.7098 19.4884 15.8617C19.6364 16.0137 19.7499 16.1956 19.8211 16.3951C19.8923 16.5947 19.9196 16.8072 19.9011 17.0182C19.8917 17.3597 19.7726 17.6892 19.5613 17.9582C19.3499 18.2271 19.0575 18.4213 18.7271 18.512L18.2174 18.7136C17.7183 18.8335 17.2826 19.1359 16.9966 19.5608C16.7105 19.9858 16.5949 20.5023 16.6727 21.0082L16.6882 21.5458ZM17.3833 24.8736C17.5208 24.8785 17.6579 24.8558 17.7864 24.807C17.915 24.7581 18.0324 24.684 18.1318 24.5891C18.2312 24.4943 18.3105 24.3805 18.3649 24.2545C18.4194 24.1286 18.4481 23.993 18.4491 23.8558C18.4479 23.7188 18.4191 23.5833 18.3645 23.4575C18.31 23.3317 18.2307 23.2181 18.1313 23.1234C18.032 23.0286 17.9146 22.9547 17.7861 22.9059C17.6576 22.8572 17.5207 22.8345 17.3833 22.8394C17.2459 22.8345 17.1089 22.8572 16.9804 22.9059C16.852 22.9547 16.7346 23.0286 16.6352 23.1234C16.5359 23.2181 16.4566 23.3317 16.402 23.4575C16.3474 23.5833 16.3187 23.7188 16.3174 23.8558C16.3185 23.993 16.3471 24.1286 16.4016 24.2545C16.4561 24.3805 16.5354 24.4943 16.6347 24.5891C16.7341 24.684 16.8515 24.7581 16.9801 24.807C17.1087 24.8558 17.2458 24.8785 17.3833 24.8736V24.8736Z'
        fill='white'
      />
      <g filter='url(#filter1_d_1435_6880)'>
        <path
          d='M39.7022 37.1H134.489C135.42 37.1 136.313 37.4687 136.972 38.1251C137.63 38.7815 138 39.6717 138 40.6V58.1C138 59.0282 137.63 59.9185 136.972 60.5748C136.313 61.2312 135.42 61.6 134.489 61.6H39.7022C38.7711 61.6 37.8781 61.2312 37.2198 60.5748C36.5614 59.9185 36.1915 59.0282 36.1915 58.1V40.6C36.1915 39.6717 36.5614 38.7815 37.2198 38.1251C37.8781 37.4687 38.7711 37.1 39.7022 37.1Z'
          fill='white'
        />
      </g>
      <path
        d='M84.6385 42.6998H66.3832C65.2199 42.6998 64.2769 43.64 64.2769 44.7998C64.2769 45.9596 65.2199 46.8998 66.3832 46.8998H84.6385C85.8018 46.8998 86.7449 45.9596 86.7449 44.7998C86.7449 43.64 85.8018 42.6998 84.6385 42.6998Z'
        fill='#C9DFFF'
      />
      <path
        d='M97.2768 51.8002H66.3832C65.2199 51.8002 64.2769 52.7404 64.2769 53.9002C64.2769 55.06 65.2199 56.0002 66.3832 56.0002H97.2768C98.4401 56.0002 99.3832 55.06 99.3832 53.9002C99.3832 52.7404 98.4401 51.8002 97.2768 51.8002Z'
        fill='#EEF5FF'
      />
      <path
        d='M55.1491 39.2H41.8087C39.8699 39.2 38.2981 40.767 38.2981 42.7V56C38.2981 57.9329 39.8699 59.4999 41.8087 59.4999H55.1491C57.088 59.4999 58.6598 57.9329 58.6598 56V42.7C58.6598 40.767 57.088 39.2 55.1491 39.2Z'
        fill='#1565D8'
      />
      <path
        d='M46.8798 51.6459H48.2841L48.2995 51.1069C48.2995 50.3985 48.7475 50.2137 49.3808 50.0135C50.0161 49.8333 50.5753 49.4519 50.9738 48.9267C51.3723 48.4016 51.5885 47.7614 51.5897 47.1029C51.6036 46.7009 51.5336 46.3003 51.3842 45.9266C51.2348 45.5529 51.0093 45.2142 50.7218 44.932C50.4343 44.6497 50.0911 44.43 49.7141 44.2867C49.337 44.1435 48.9342 44.0799 48.5312 44.0999C48.1053 44.0907 47.682 44.1677 47.2868 44.3265C46.8917 44.4853 46.5331 44.7224 46.2326 45.0235C45.9322 45.3246 45.6962 45.6834 45.5391 46.0781C45.3819 46.4729 45.3068 46.8954 45.3183 47.3199H46.7703C46.7648 47.0855 46.8062 46.8523 46.8923 46.6341C46.9783 46.4159 47.1072 46.217 47.2713 46.049C47.4354 45.8811 47.6315 45.7475 47.8481 45.6561C48.0647 45.5647 48.2974 45.5173 48.5326 45.5167C48.7447 45.5044 48.957 45.5379 49.155 45.6147C49.353 45.6915 49.5321 45.8099 49.6801 45.9618C49.8281 46.1138 49.9415 46.2957 50.0128 46.4952C50.084 46.6948 50.1112 46.9073 50.0927 47.1183C50.0834 47.4598 49.9643 47.7893 49.7529 48.0583C49.5416 48.3272 49.2492 48.5213 48.9188 48.6121L48.409 48.8137C47.91 48.9336 47.4743 49.236 47.1882 49.6609C46.9021 50.0859 46.7866 50.6024 46.8644 51.1083L46.8798 51.6459ZM47.5749 54.9737C47.7124 54.9786 47.8495 54.9559 47.9781 54.9071C48.1067 54.8582 48.2241 54.7841 48.3235 54.6892C48.4228 54.5944 48.5021 54.4806 48.5566 54.3546C48.6111 54.2287 48.6397 54.0931 48.6408 53.9559C48.6395 53.8189 48.6108 53.6834 48.5562 53.5576C48.5016 53.4318 48.4223 53.3182 48.323 53.2235C48.2236 53.1287 48.1062 53.0548 47.9778 53.006C47.8493 52.9573 47.7123 52.9346 47.5749 52.9395C47.4375 52.9346 47.3006 52.9573 47.1721 53.006C47.0436 53.0548 46.9262 53.1287 46.8269 53.2235C46.7275 53.3182 46.6482 53.4318 46.5937 53.5576C46.5391 53.6834 46.5103 53.8189 46.5091 53.9559C46.5101 54.0931 46.5387 54.2287 46.5932 54.3546C46.6477 54.4806 46.727 54.5944 46.8264 54.6892C46.9257 54.7841 47.0432 54.8582 47.1718 54.9071C47.3003 54.9559 47.4374 54.9786 47.5749 54.9737V54.9737Z'
        fill='white'
      />
      <g filter='url(#filter2_d_1435_6880)'>
        <path
          d='M9.51088 67.2H104.298C105.229 67.2 106.122 67.5687 106.78 68.2251C107.439 68.8815 107.809 69.7717 107.809 70.7V88.2C107.809 89.1282 107.439 90.0184 106.78 90.6748C106.122 91.3312 105.229 91.7 104.298 91.7H9.51088C8.5798 91.7 7.68686 91.3312 7.02848 90.6748C6.37011 90.0184 6.00024 89.1282 6.00024 88.2V70.7C6.00024 69.7717 6.37011 68.8815 7.02848 68.2251C7.68686 67.5687 8.5798 67.2 9.51088 67.2V67.2Z'
          fill='white'
        />
      </g>
      <path
        d='M54.4469 72.8003H36.1916C35.0283 72.8003 34.0852 73.7405 34.0852 74.9003C34.0852 76.0601 35.0283 77.0003 36.1916 77.0003H54.4469C55.6102 77.0003 56.5533 76.0601 56.5533 74.9003C56.5533 73.7405 55.6102 72.8003 54.4469 72.8003Z'
        fill='#C9DFFF'
      />
      <path
        d='M67.0853 81.9H36.1917C35.0284 81.9 34.0853 82.8402 34.0853 84C34.0853 85.1598 35.0284 86.1 36.1917 86.1H67.0853C68.2486 86.1 69.1917 85.1598 69.1917 84C69.1917 82.8402 68.2486 81.9 67.0853 81.9Z'
        fill='#EEF5FF'
      />
      <path
        d='M24.9576 69.3H11.6172C9.67833 69.3 8.10657 70.8671 8.10657 72.8V86.1C8.10657 88.033 9.67833 89.6 11.6172 89.6H24.9576C26.8965 89.6 28.4682 88.033 28.4682 86.1V72.8C28.4682 70.8671 26.8965 69.3 24.9576 69.3Z'
        fill='#1565D8'
      />
      <path
        d='M16.688 81.746H18.0923L18.1077 81.207C18.1077 80.4986 18.5557 80.3138 19.189 80.1136C19.8243 79.9334 20.3835 79.552 20.782 79.0268C21.1805 78.5017 21.3967 77.8615 21.3979 77.203C21.4118 76.801 21.3419 76.4004 21.1925 76.0267C21.0431 75.653 20.8175 75.3143 20.53 75.032C20.2425 74.7498 19.8994 74.5301 19.5223 74.3868C19.1452 74.2436 18.7425 74.18 18.3394 74.2C17.9135 74.1908 17.4902 74.2678 17.0951 74.4266C16.7 74.5854 16.3413 74.8225 16.0409 75.1236C15.7404 75.4247 15.5045 75.7835 15.3473 76.1782C15.1901 76.573 15.115 76.9954 15.1265 77.42H16.5785C16.573 77.1856 16.6145 76.9524 16.7005 76.7342C16.7865 76.516 16.9154 76.3171 17.0795 76.1491C17.2437 75.9812 17.4398 75.8476 17.6564 75.7562C17.8729 75.6648 18.1057 75.6174 18.3409 75.6168C18.5529 75.6045 18.7652 75.638 18.9632 75.7148C19.1612 75.7916 19.3403 75.91 19.4883 76.0619C19.6363 76.2139 19.7498 76.3958 19.821 76.5953C19.8922 76.7949 19.9195 77.0074 19.901 77.2184C19.8916 77.5599 19.7725 77.8894 19.5611 78.1584C19.3498 78.4273 19.0574 78.6214 18.727 78.7122L18.2173 78.9138C17.7182 79.0337 17.2825 79.336 16.9964 79.761C16.7104 80.186 16.5948 80.7025 16.6726 81.2084L16.688 81.746ZM17.3831 85.0738C17.5207 85.0787 17.6577 85.056 17.7863 85.0072C17.9149 84.9583 18.0323 84.8842 18.1317 84.7893C18.2311 84.6945 18.3103 84.5807 18.3648 84.4547C18.4193 84.3288 18.4479 84.1932 18.449 84.056C18.4478 83.919 18.419 83.7835 18.3644 83.6577C18.3098 83.5319 18.2305 83.4183 18.1312 83.3236C18.0318 83.2288 17.9145 83.1549 17.786 83.1061C17.6575 83.0574 17.5205 83.0347 17.3831 83.0396C17.2458 83.0347 17.1088 83.0574 16.9803 83.1061C16.8518 83.1549 16.7345 83.2288 16.6351 83.3236C16.5358 83.4183 16.4565 83.5319 16.4019 83.6577C16.3473 83.7835 16.3185 83.919 16.3173 84.056C16.3184 84.1932 16.347 84.3288 16.4015 84.4547C16.456 84.5807 16.5352 84.6945 16.6346 84.7893C16.734 84.8842 16.8514 84.9583 16.98 85.0072C17.1086 85.056 17.2456 85.0787 17.3831 85.0738V85.0738Z'
        fill='white'
      />
      <defs>
        <filter
          id='filter0_d_1435_6880'
          x='0.000244141'
          y='4'
          width='113.808'
          height='36.5'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1435_6880' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1435_6880' result='shape' />
        </filter>
        <filter
          id='filter1_d_1435_6880'
          x='30.1915'
          y='34.1'
          width='113.808'
          height='36.5'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1435_6880' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1435_6880' result='shape' />
        </filter>
        <filter
          id='filter2_d_1435_6880'
          x='0.000244141'
          y='64.2'
          width='113.808'
          height='36.5'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_1435_6880' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_1435_6880' result='shape' />
        </filter>
      </defs>
    </svg>
  );
};
