import { useState } from 'react';
import { Button } from 'antd';
import { Trash2 } from 'lucide-react';
import { ShowToast } from './ShowToast';
import { CustomModal } from '@common/components';
import { useDeleteMutation } from '@services';
import { DEFAULT_ERROR_MESSAGE, ERROR, SUCCESS } from '@constants';

export const DeleteModal = ({
  element,
  item,
  id = null,
  apiUrl,
  deleteSuccessMessage = 'Deleted Successfully',
  queryKey,
  onDeleteSuccess = () => null,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutate, isPending } = useDeleteMutation(
    apiUrl,
    () => {
      setIsModalOpen(false);
      ShowToast(SUCCESS, deleteSuccessMessage);
      onDeleteSuccess();
    },
    () => ShowToast(ERROR, DEFAULT_ERROR_MESSAGE),
    { queryKey }
  );

  return (
    <>
      <Button
        className='h-min border-none p-0'
        onClick={() => setIsModalOpen(true)}
        onCancel={() => setIsModalOpen(false)}
      >
        <Trash2 color='#D92D20' className='h-5 w-5' />
      </Button>
      <CustomModal
        textPrimary='Cancel'
        textSecondary='Delete'
        open={isModalOpen}
        cancel={() => setIsModalOpen(false)}
        heading={`Delete ${element}`}
        text={`Are you sure you want to delete this ${item}? This action cannot be undone.`}
        textClass='mt-2 w-[22rem]'
        footerBtnClass='w-1/2'
        SVG='AlertCircleIcon'
        secondBtnClick={() => mutate({ id })}
        firstBtnClick={() => setIsModalOpen(false)}
        loading={isPending}
      />
    </>
  );
};
