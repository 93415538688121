import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShowToast } from '@common/components/ShowToast';
import { Button } from '@ui';
import { SignInForm, SignInImg } from '.';
import { useFormikForm } from '@common/hooks';
import { SignInValidationSchema } from '@common/validators';
import { ROUTES } from '@routes';
import { apiEndpoints, usePostMutation } from '@services';
import { AppContext } from '@useContext';
import { setLocalStorageItem } from '@utils';
import { ACCESS_TOKEN, DEFAULT_ERROR_MESSAGE, ERROR, REFRESH_TOKEN } from '@constants';
import { Logo } from '@assets/svgs';

const SIGN_IN_FORM_INITIAL_DATA = {
  email: '',
  password: '',
};

const SignIn = () => {
  const navigate = useNavigate();
  const { refetchUser } = useContext(AppContext);

  const formik = useFormikForm(SIGN_IN_FORM_INITIAL_DATA, handleSignIn, SignInValidationSchema);
  const { mutate: signIn, isPending } = usePostMutation(apiEndpoints.auth.SIGN_IN, signInOnSuccess, error =>
    ShowToast(ERROR, error?.response?.data?.detail || DEFAULT_ERROR_MESSAGE)
  );

  function handleSignIn() {
    signIn({ data: formik.values });
  }

  function signInOnSuccess(data) {
    setLocalStorageItem(ACCESS_TOKEN, data.data.accessToken);
    setLocalStorageItem(REFRESH_TOKEN, data.data.refreshToken);
    navigate(ROUTES.UPLOAD_NEW);
    refetchUser();
  }

  return (
    <div className='relative grid grid-cols-12 gap-x-6 ps-[3.875rem]'>
      <div className='col-span-4 col-start-2 h-screen pt-8'>
        <Logo className='absolute left-[3.9375rem] top-8 h-[1.1875rem] w-[7.8125rem]' />
        <div className='col-span-4 col-start-2 mt-[14.8125rem]'>
          <form onSubmit={formik.handleSubmit}>
            <SignInForm formik={formik} />
            <Button
              type='submit'
              className='mt-6 h-11 w-full rounded-lg bg-royalBlue text-base font-semibold leading-5 text-white'
              disabled={isPending}
            >
              {isPending ? 'Please wait...' : 'Sign In'}
            </Button>
          </form>
        </div>
      </div>
      <SignInImg />
    </div>
  );
};

export default SignIn;
