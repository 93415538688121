export const FileUploadIcon = ({ className }) => {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g clipPath='url(#clip0_305_8683)'>
        <path
          d='M39.9 11.572L32.93 4.60195C30.286 1.95795 26.77 0.501954 23.03 0.501954H14C8.486 0.499954 4 4.98595 4 10.5V38.5C4 44.014 8.486 48.5 14 48.5H34C39.514 48.5 44 44.014 44 38.5V21.47C44 17.73 42.544 14.218 39.9 11.572ZM37.072 14.4C37.708 15.036 38.246 15.742 38.682 16.5H30C28.898 16.5 28 15.602 28 14.5V5.81795C28.758 6.25395 29.466 6.79195 30.1 7.42795L37.07 14.398L37.072 14.4ZM40 38.5C40 41.808 37.308 44.5 34 44.5H14C10.692 44.5 8 41.808 8 38.5V10.5C8 7.19195 10.692 4.49995 14 4.49995H23.03C23.356 4.49995 23.68 4.51595 24 4.54595V14.5C24 17.808 26.692 20.5 30 20.5H39.954C39.984 20.82 40 21.144 40 21.47V38.5Z'
          fill='#BCD2FE'
        />
        <path
          d='M31.4141 32.29C32.1961 31.508 32.1961 30.244 31.4141 29.462H31.4161L28.1881 26.234C25.8781 23.926 22.1221 23.926 19.8141 26.234L16.5861 29.462C15.8041 30.244 15.8041 31.508 16.5861 32.29C17.3681 33.072 18.6321 33.072 19.4141 32.29L22.0001 29.704V38.5C22.0001 39.604 22.8961 40.5 24.0001 40.5C25.1041 40.5 26.0001 39.604 26.0001 38.5V29.704L28.5861 32.29C28.9761 32.68 29.4881 32.876 30.0001 32.876C30.5121 32.876 31.0241 32.68 31.4141 32.29Z'
          fill='#32A071'
        />
      </g>
      <defs>
        <clipPath id='clip0_305_8683'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
