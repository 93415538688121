import * as Yup from 'yup';
import { DEFAULT_FIELD_ERROR_MESSAGE } from '@constants';

export const VendorModalValidationSchema = Yup.object({
  name: Yup.string().required(DEFAULT_FIELD_ERROR_MESSAGE).max(30, 'Name must be at most 30 characters'),
});

export const VendorDropdownValidationSchema = Yup.object({
  selectedVendor: Yup.object()
    .nullable()
    .required(DEFAULT_FIELD_ERROR_MESSAGE)
    .test(
      'is-not-null-or-empty',
      DEFAULT_FIELD_ERROR_MESSAGE,
      value => value !== null && Object.keys(value).length > 0
    ),
});

export const CreateDocumentValidationSchema = Yup.object({
  file: Yup.object()
    .nullable()
    .required(DEFAULT_FIELD_ERROR_MESSAGE)
    .test(
      'is-not-null-or-empty',
      DEFAULT_FIELD_ERROR_MESSAGE,
      value => value !== null && Object.keys(value).length > 0
    ),
  documentTrainingTableData: Yup.array()
    .min(1, 'You must add at least one row or section in the table')
    .required(DEFAULT_FIELD_ERROR_MESSAGE),
});
