import { Outlet, useLocation } from 'react-router-dom';
import { Header, Sidebar } from './common/components';
import { ROUTES } from '@routes';

export const Layout = () => {
  const location = useLocation();
  const isUploadNewRoute = location.pathname === ROUTES.UPLOAD_NEW;
  const isPdf = true;

  return (
    <div className='flex'>
      <Sidebar />
      <div className='flex flex-1 flex-col'>
        <Header />
        <div className={`grid flex-1 grid-cols-12 gap-x-6 bg-lightGray px-8 pt-6 ${isUploadNewRoute ? 'pb-11' : ''}`}>
          <div
            className={`col-span-12 h-full ${isUploadNewRoute ? 'rounded-b-xl' : ''} ${
              isUploadNewRoute && isPdf
                ? 'border-none bg-lightGray shadow-none'
                : 'shadow-secondary rounded-xl border border-baseColor bg-white'
            }`}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};
