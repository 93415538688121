export const ROUTES = {
  BASE: '/',
  UN_AUTHORIZED: '/un-authorized',
  UPLOAD_NEW: '/upload-new',
  DOCUMENT_SETUP: '/document-setup',
  PROCESSED_DOCUMENTS: '/processed-documents',
  VENDORS: '/vendors',
  SIGN_IN: '/sign-in',
  NOT_MATCHED: '*',
};
