export const ShortLogo = ({ className }) => {
  return (
    <svg
      width='23'
      height='21'
      viewBox='0 0 23 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M0 20.6869V0H14.1751V3.14141H3.94816V8.75757H13.4408V11.899H3.94816V17.5454H14.2602V20.6869H0Z'
        fill='#1565D8'
      />
      <path d='M22.2084 0V20.6869H18.2603V0H22.2084Z' fill='#1565D8' />
    </svg>
  );
};
