import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { getNameInitials } from '@cn';
import { Avatar, AvatarImage, AvatarFallback } from '@ui';
import { Notifications } from '.';
import { AppContext } from '@useContext';
import { routesTitle } from '@constants';

export const Header = () => {
  const { user } = useContext(AppContext);
  const location = useLocation();

  const getHeaderTitle = pathName => {
    return Object.keys(routesTitle).find(route => pathName.includes(route))
      ? routesTitle[Object.keys(routesTitle).find(route => pathName.includes(route))]
      : 'Upload New';
  };

  return (
    <div className='flex items-center justify-between px-8 py-3.5'>
      <h2 className='text-2xl'>{getHeaderTitle(location.pathname)}</h2>
      <div className='flex items-center gap-4'>
        <Notifications />
        <Avatar>
          <AvatarImage src='' alt='@shadcn' />
          <AvatarFallback className='bg-SelectionColor'>
            {getNameInitials(user?.firstName, user?.lastName)}
          </AvatarFallback>
        </Avatar>
      </div>
    </div>
  );
};
