import { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export const PdfViewer = ({ url, file }) => {
  const [numPages, setNumPages] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadedPages, setLoadedPages] = useState([]);

  useEffect(() => {
    if (numPages && loadedPages.length === numPages) setLoading(false);
  }, [loadedPages, numPages]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onPageLoadSuccess(pageNumber) {
    setLoadedPages(prevLoadedPages => [...prevLoadedPages, pageNumber]);
  }

  return (
    <>
      <Document
        file={file || url}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={() => setLoading(false)}
        error={
          <div className='xs:h-[calc(100vh-210px)] flex h-[calc(100vh-100px)] items-center justify-center md:h-[calc(100vh-326px)] lg:h-[calc(100vh-250px)] 2xl:h-[calc(100vh-300px)]'>
            Error loading PDF
          </div>
        }
        onSourceError={() => setLoading(false)}
      >
        <div
          className={`xs:h-[calc(100vh-324px)] h-[calc(100vh-200px)] overflow-auto md:h-[calc(100vh-326px)] lg:h-[calc(100vh-250px)] 2xl:h-[calc(100vh-300px)] ${
            loading ? 'hidden' : ''
          }`}
        >
          {numPages &&
            Array.from({ length: numPages }, (_, index) => index + 1).map(pageNumber => (
              <Page
                key={pageNumber}
                pageNumber={pageNumber}
                onLoadSuccess={() => onPageLoadSuccess(pageNumber)}
                onLoadError={() => setLoading(false)}
                onSourceError={() => setLoading(false)}
                error={
                  <div className='xs:h-[calc(100vh-210px)] flex h-[calc(100vh-100px)] items-center justify-center md:h-[calc(100vh-326px)] lg:h-[calc(100vh-250px)] 2xl:h-[calc(100vh-300px)]'>
                    Error loading PDF
                  </div>
                }
              />
            ))}
        </div>
      </Document>
    </>
  );
};
