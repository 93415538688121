export const SignInImage = ({ className }) => {
  return (
    <svg
      width='auto'
      className={className}
      height='450'
      viewBox='0 0 584 450'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M423.271 292.91L347.657 253.992C346.517 253.404 345.657 252.388 345.266 251.168C344.875 249.947 344.986 248.622 345.572 247.483L350.25 238.426C350.839 237.288 351.857 236.43 353.079 236.04C354.302 235.649 355.63 235.759 356.771 236.345L432.385 275.263C433.525 275.851 434.385 276.867 434.776 278.088C435.166 279.308 435.056 280.633 434.469 281.773L429.792 290.829C429.502 291.394 429.102 291.896 428.617 292.306C428.131 292.715 427.569 293.025 426.962 293.216C426.483 293.37 425.983 293.448 425.48 293.449C424.711 293.448 423.953 293.263 423.271 292.91Z'
        fill='#FFC79E'
      />
      <path
        d='M400.218 256.504C400.815 256.272 401.452 256.161 402.093 256.177C402.734 256.194 403.364 256.338 403.948 256.601L428.728 270.279C431.44 271.775 433.472 274.253 434.406 277.202C435.34 280.151 435.103 283.345 433.744 286.125C432.446 288.781 430.218 290.87 427.48 291.995C424.743 293.12 421.687 293.203 418.892 292.229L392.018 282.852C391.436 282.596 390.911 282.227 390.472 281.768C390.033 281.309 389.688 280.767 389.459 280.175C389.229 279.583 389.119 278.951 389.134 278.316C389.149 277.682 389.29 277.056 389.547 276.475L397.537 259.057C397.677 258.741 397.849 258.442 398.051 258.163C398.598 257.413 399.35 256.837 400.218 256.504Z'
        fill='#2F2E41'
      />
      <path
        d='M457.233 252.325C477.674 252.325 494.245 235.783 494.245 215.377C494.245 194.971 477.674 178.428 457.233 178.428C436.792 178.428 420.221 194.971 420.221 215.377C420.221 235.783 436.792 252.325 457.233 252.325Z'
        fill='#FFC79E'
      />
      <path
        d='M452.691 445.167V360.241C452.692 358.96 453.203 357.731 454.11 356.825C455.018 355.919 456.248 355.41 457.532 355.408H467.739C469.022 355.41 470.253 355.919 471.16 356.825C472.068 357.731 472.578 358.96 472.58 360.241V445.167C472.578 446.448 472.068 447.677 471.16 448.583C470.253 449.489 469.022 449.998 467.739 450H457.532C456.248 449.998 455.018 449.489 454.11 448.583C453.203 447.677 452.692 446.448 452.691 445.167Z'
        fill='#FFC79E'
      />
      <path
        d='M420.561 445.167V360.241C420.562 358.96 421.073 357.731 421.98 356.825C422.888 355.919 424.119 355.41 425.402 355.408H435.609C436.892 355.41 438.123 355.919 439.031 356.825C439.938 357.731 440.449 358.96 440.45 360.241V445.167C440.449 446.448 439.938 447.677 439.031 448.583C438.123 449.489 436.892 449.998 435.609 450H425.402C424.119 449.998 422.888 449.489 421.981 448.583C421.073 447.677 420.562 446.448 420.561 445.167Z'
        fill='#FFC79E'
      />
      <path
        d='M458.01 229.071L458.049 227.69C455.476 227.619 453.207 227.458 451.503 226.215C451.019 225.842 450.622 225.37 450.338 224.831C450.054 224.291 449.889 223.697 449.856 223.088C449.826 222.717 449.882 222.343 450.02 221.996C450.159 221.65 450.375 221.34 450.653 221.09C451.784 220.136 453.605 220.445 454.932 221.052L456.077 221.576L453.882 205.566L452.512 205.753L454.379 219.372C452.575 218.843 450.904 219.071 449.76 220.036C449.321 220.419 448.976 220.898 448.753 221.436C448.53 221.974 448.434 222.556 448.474 223.137C448.514 223.955 448.733 224.754 449.115 225.478C449.498 226.202 450.034 226.834 450.687 227.33C452.877 228.928 455.723 229.007 458.01 229.071Z'
        fill='#2F2E41'
      />
      <path d='M445.735 206.928H438.286V208.308H445.735V206.928Z' fill='#2F2E41' />
      <path d='M469.248 206.928H461.799V208.308H469.248V206.928Z' fill='#2F2E41' />
      <path
        d='M492.651 374.294L479.849 290.334C479.658 289.068 479.977 287.777 480.738 286.745C481.498 285.713 482.638 285.024 483.907 284.83L493.998 283.296C495.267 283.105 496.56 283.424 497.594 284.184C498.627 284.943 499.317 286.081 499.512 287.347L512.313 371.306C512.505 372.573 512.185 373.864 511.425 374.896C510.664 375.928 509.524 376.617 508.256 376.811L498.165 378.344C497.536 378.44 496.895 378.412 496.278 378.26C495.66 378.108 495.079 377.837 494.566 377.461C494.054 377.084 493.621 376.611 493.293 376.068C492.964 375.524 492.746 374.922 492.651 374.294Z'
        fill='#FFC79E'
      />
      <path
        d='M482.757 320.404C482.218 320.059 481.754 319.609 481.391 319.082C481.028 318.554 480.774 317.96 480.644 317.334L477.173 289.28C476.792 286.211 477.607 283.113 479.449 280.626C481.291 278.14 484.019 276.454 487.071 275.919C489.986 275.406 492.989 275.98 495.509 277.53C498.029 279.08 499.892 281.5 500.745 284.33L508.933 311.548C509.067 312.169 509.077 312.81 508.962 313.435C508.848 314.059 508.612 314.655 508.266 315.189C507.921 315.722 507.474 316.182 506.95 316.543C506.427 316.904 505.837 317.158 505.215 317.291L486.401 321.07C486.064 321.143 485.72 321.18 485.375 321.18C484.446 321.179 483.536 320.91 482.757 320.404Z'
        fill='#2F2E41'
      />
      <path
        d='M483.751 381.091H384.841C383.838 381.091 382.852 380.834 381.976 380.346C381.1 379.858 380.364 379.154 379.837 378.302C379.311 377.449 379.012 376.477 378.968 375.476C378.925 374.476 379.139 373.481 379.589 372.586L427.411 277.65C430.733 271.054 436.188 265.77 442.892 262.652C449.597 259.534 457.159 258.765 464.355 260.47C471.551 262.174 477.961 266.252 482.547 272.045C487.134 277.837 489.629 285.004 489.629 292.388V375.222C489.628 376.778 489.008 378.27 487.906 379.37C486.804 380.47 485.309 381.089 483.751 381.091Z'
        fill='#9094A0'
      />
      <path
        d='M458.544 184.893C450.967 186.582 444.577 191.512 436.958 193.045C433.487 193.743 429.664 193.715 426.476 192.009C424.114 190.729 422.309 188.625 421.403 186.1C420.508 183.517 420.277 180.75 420.732 178.055C421.188 175.36 422.314 172.822 424.009 170.675C428.285 165.45 435.103 163.042 441.645 162.363C454.736 161.005 468.183 165.311 478.839 172.813C489.841 180.559 498.282 192.19 501.113 205.428C502.626 212.504 502.477 220.031 499.893 226.845C497.408 233.365 492.516 238.688 486.222 241.719C484.719 242.443 483.153 243.025 481.543 243.459C481.286 243.539 481.07 243.716 480.941 243.952C480.813 244.187 480.781 244.464 480.852 244.723C480.924 244.982 481.093 245.203 481.325 245.34C481.557 245.477 481.832 245.518 482.094 245.456C489.171 243.526 495.276 239.038 499.222 232.864C503.276 226.392 504.635 218.468 504.011 210.938C502.803 196.371 494.78 182.983 483.562 173.817C472.786 165.012 458.846 159.655 444.83 160.08C437.8 160.294 430.504 162.075 425.037 166.699C422.682 168.688 420.858 171.23 419.731 174.096C418.603 176.963 418.207 180.064 418.577 183.121C418.891 185.982 420.029 188.69 421.852 190.918C424.082 193.433 427.172 195.026 430.516 195.385C438.804 196.462 445.991 191.772 453.314 188.758C455.184 187.971 457.118 187.347 459.096 186.89C460.398 186.6 459.847 184.603 458.544 184.893Z'
        fill='#2F2E41'
      />
      <path
        d='M72.1026 264.416H11.7566C8.63966 264.413 5.65135 263.175 3.44731 260.975C1.24328 258.774 0.00350892 255.791 0 252.68V11.7365C0.00350892 8.62486 1.24328 5.64167 3.44731 3.44141C5.65135 1.24115 8.63966 0.0035029 11.7566 0H175.437L205.395 26.7027V75.2516H204.012V27.321L174.909 1.38076H11.7566C9.00636 1.38387 6.36963 2.47591 4.42489 4.41732C2.48016 6.35872 1.38624 8.99093 1.38313 11.7365V252.68C1.38624 255.425 2.48016 258.057 4.42489 259.999C6.36963 261.94 9.00636 263.032 11.7566 263.035H72.1026V264.416Z'
        fill='#CCCCCC'
      />
      <path
        d='M204.704 27.6153H186.032C182.915 27.6118 179.926 26.3741 177.722 24.1739C175.518 21.9736 174.278 18.9904 174.275 15.8788V0.690381C174.275 0.507281 174.348 0.331679 174.477 0.202207C174.607 0.0727359 174.783 0 174.966 0C175.15 0 175.326 0.0727359 175.455 0.202207C175.585 0.331679 175.658 0.507281 175.658 0.690381V15.8788C175.661 18.6243 176.755 21.2565 178.7 23.1979C180.645 25.1393 183.281 26.2314 186.032 26.2345H204.704C204.887 26.2345 205.063 26.3072 205.193 26.4367C205.323 26.5662 205.395 26.7418 205.395 26.9249C205.395 27.108 205.323 27.2836 205.193 27.4131C205.063 27.5425 204.887 27.6153 204.704 27.6153Z'
        fill='#CCCCCC'
      />
      <path
        d='M181.564 62.4795H23.8865C22.4192 62.4795 21.012 63.0614 19.9744 64.0972C18.9369 65.1329 18.354 66.5377 18.354 68.0025C18.354 69.4673 18.9369 70.8722 19.9744 71.9079C21.012 72.9437 22.4192 73.5256 23.8865 73.5256H181.564C183.031 73.5256 184.438 72.9437 185.476 71.9079C186.513 70.8722 187.096 69.4673 187.096 68.0025C187.096 66.5377 186.513 65.1329 185.476 64.0972C184.438 63.0614 183.031 62.4795 181.564 62.4795Z'
        fill='#E6E6E6'
      />
      <path
        d='M102.034 39.0066H23.8865C22.4192 39.0066 21.012 39.5885 19.9744 40.6243C18.9369 41.66 18.354 43.0648 18.354 44.5296C18.354 45.9944 18.9369 47.3993 19.9744 48.435C21.012 49.4708 22.4192 50.0527 23.8865 50.0527H102.034C103.501 50.0527 104.908 49.4708 105.946 48.435C106.983 47.3993 107.566 45.9944 107.566 44.5296C107.566 43.0648 106.983 41.66 105.946 40.6243C104.908 39.5885 103.501 39.0066 102.034 39.0066Z'
        fill='#E6E6E6'
      />
      <path
        d='M23.8865 108.735C22.4192 108.735 21.012 109.317 19.9744 110.353C18.9369 111.389 18.354 112.793 18.354 114.258C18.354 115.723 18.9369 117.128 19.9744 118.164C21.012 119.199 22.4192 119.781 23.8865 119.781H73.3059V108.735H23.8865Z'
        fill='#E6E6E6'
      />
      <path
        d='M23.8865 85.2622C23.16 85.2622 22.4406 85.4051 21.7693 85.6826C21.0981 85.9602 20.4882 86.367 19.9744 86.8799C19.4607 87.3927 19.0532 88.0016 18.7751 88.6717C18.4971 89.3418 18.354 90.06 18.354 90.7853C18.354 91.5106 18.4971 92.2287 18.7751 92.8988C19.0532 93.5689 19.4607 94.1778 19.9744 94.6906C20.4882 95.2035 21.0981 95.6103 21.7693 95.8879C22.4406 96.1655 23.16 96.3083 23.8865 96.3083H73.3059V85.2622H23.8865Z'
        fill='#E6E6E6'
      />
      <path
        d='M73.6519 225.755H29.364C26.3387 225.751 23.4383 224.55 21.2991 222.414C19.1599 220.279 17.9565 217.383 17.9531 214.363V156.371C17.9565 153.351 19.1599 150.456 21.2991 148.32C23.4383 146.185 26.3387 144.983 29.364 144.98H73.6519V225.755Z'
        fill='#E6E6E6'
      />
      <path
        d='M145.063 338.632H84.7171C81.6001 338.629 78.6118 337.391 76.4078 335.191C74.2037 332.99 72.964 330.007 72.9604 326.896V85.9525C72.964 82.8409 74.2037 79.8577 76.4078 77.6575C78.6118 75.4572 81.6001 74.2196 84.7171 74.2161H248.398L278.356 100.919V146.706H276.973V101.537L247.87 75.5968H84.7171C81.9668 75.5999 79.3301 76.692 77.3854 78.6334C75.4406 80.5748 74.3467 83.207 74.3436 85.9525V326.896C74.3467 329.641 75.4406 332.273 77.3854 334.215C79.3301 336.156 81.9668 337.248 84.7171 337.251H145.063V338.632Z'
        fill='#CCCCCC'
      />
      <path
        d='M277.664 101.831H258.991C255.875 101.828 252.886 100.59 250.682 98.3899C248.478 96.1897 247.238 93.2065 247.235 90.0948V74.9064C247.235 74.7233 247.308 74.5477 247.437 74.4183C247.567 74.2888 247.743 74.2161 247.926 74.2161C248.11 74.2161 248.286 74.2888 248.415 74.4183C248.545 74.5477 248.618 74.7233 248.618 74.9064V90.0948C248.621 92.8404 249.715 95.4726 251.66 97.414C253.604 99.3554 256.241 100.447 258.991 100.451H277.664C277.847 100.451 278.023 100.523 278.153 100.653C278.283 100.782 278.355 100.958 278.355 101.141C278.355 101.324 278.283 101.5 278.153 101.629C278.023 101.759 277.847 101.831 277.664 101.831Z'
        fill='#CCCCCC'
      />
      <path
        d='M254.524 136.696H96.847C95.3797 136.696 93.9724 137.277 92.9349 138.313C91.8973 139.349 91.3145 140.754 91.3145 142.219C91.3145 143.683 91.8973 145.088 92.9349 146.124C93.9724 147.16 95.3797 147.742 96.847 147.742H254.524C255.991 147.742 257.399 147.16 258.436 146.124C259.474 145.088 260.057 143.683 260.057 142.219C260.057 140.754 259.474 139.349 258.436 138.313C257.399 137.277 255.991 136.696 254.524 136.696Z'
        fill='#E6E6E6'
      />
      <path
        d='M174.994 113.223H96.847C95.3797 113.223 93.9724 113.805 92.9349 114.84C91.8973 115.876 91.3145 117.281 91.3145 118.746C91.3145 120.211 91.8973 121.615 92.9349 122.651C93.9724 123.687 95.3797 124.269 96.847 124.269H174.994C176.461 124.269 177.869 123.687 178.906 122.651C179.944 121.615 180.527 120.211 180.527 118.746C180.527 117.281 179.944 115.876 178.906 114.84C177.869 113.805 176.461 113.223 174.994 113.223Z'
        fill='#E6E6E6'
      />
      <path
        d='M254.524 136.696H96.847C95.3797 136.696 93.9724 137.277 92.9349 138.313C91.8973 139.349 91.3145 140.754 91.3145 142.219C91.3145 143.683 91.8973 145.088 92.9349 146.124C93.9724 147.16 95.3797 147.742 96.847 147.742H254.524C255.991 147.742 257.399 147.16 258.436 146.124C259.474 145.088 260.057 143.683 260.057 142.219C260.057 140.754 259.474 139.349 258.436 138.313C257.399 137.277 255.991 136.696 254.524 136.696Z'
        fill='#E6E6E6'
      />
      <path
        d='M174.994 113.223H96.847C95.3797 113.223 93.9724 113.805 92.9349 114.84C91.8973 115.876 91.3145 117.281 91.3145 118.746C91.3145 120.211 91.8973 121.615 92.9349 122.651C93.9724 123.687 95.3797 124.269 96.847 124.269H174.994C176.461 124.269 177.869 123.687 178.906 122.651C179.944 121.615 180.527 120.211 180.527 118.746C180.527 117.281 179.944 115.876 178.906 114.84C177.869 113.805 176.461 113.223 174.994 113.223Z'
        fill='#E6E6E6'
      />
      <path
        d='M96.847 182.951C95.3797 182.951 93.9724 183.533 92.9349 184.569C91.8973 185.605 91.3145 187.009 91.3145 188.474C91.3145 189.939 91.8973 191.344 92.9349 192.38C93.9724 193.415 95.3797 193.997 96.847 193.997H146.266V182.951H96.847Z'
        fill='#E6E6E6'
      />
      <path
        d='M96.847 159.478C95.3797 159.478 93.9724 160.06 92.9349 161.096C91.8973 162.131 91.3145 163.536 91.3145 165.001C91.3145 166.466 91.8973 167.871 92.9349 168.906C93.9724 169.942 95.3797 170.524 96.847 170.524H146.266V159.478H96.847Z'
        fill='#E6E6E6'
      />
      <path
        d='M96.847 182.951C95.3797 182.951 93.9724 183.533 92.9349 184.569C91.8973 185.605 91.3145 187.009 91.3145 188.474C91.3145 189.939 91.8973 191.344 92.9349 192.38C93.9724 193.415 95.3797 193.997 96.847 193.997H146.266V182.951H96.847Z'
        fill='#E6E6E6'
      />
      <path
        d='M96.847 159.478C95.3797 159.478 93.9724 160.06 92.9349 161.096C91.8973 162.131 91.3145 163.536 91.3145 165.001C91.3145 166.466 91.8973 167.871 92.9349 168.906C93.9724 169.942 95.3797 170.524 96.847 170.524H146.266V159.478H96.847Z'
        fill='#E6E6E6'
      />
      <path
        d='M146.612 299.971H102.324C99.2986 299.967 96.3982 298.766 94.259 296.63C92.1198 294.495 90.9165 291.6 90.9131 288.579V230.587C90.9165 227.567 92.1198 224.672 94.259 222.536C96.3982 220.401 99.2986 219.199 102.324 219.196H146.612V299.971Z'
        fill='#E6E6E6'
      />
      <path
        d='M150.513 149.122C148.794 150.17 147.374 151.64 146.388 153.393C145.402 155.146 144.884 157.123 144.883 159.133V160.859H146.266V159.133C146.269 156.85 147.026 154.631 148.42 152.822C149.815 151.012 151.768 149.712 153.977 149.122H150.513ZM320.32 147.396H156.64C153.523 147.4 150.535 148.638 148.331 150.838C146.127 153.038 144.887 156.021 144.883 159.133V400.076C144.888 403.187 146.128 406.17 148.332 408.37C150.535 410.57 153.523 411.808 156.64 411.813H338.522C341.639 411.808 344.626 410.57 346.83 408.37C349.034 406.17 350.274 403.187 350.279 400.076V174.1L320.32 147.396ZM348.895 400.076C348.892 402.822 347.798 405.454 345.853 407.395C343.909 409.336 341.272 410.428 338.522 410.432H156.64C153.89 410.428 151.253 409.336 149.308 407.395C147.364 405.454 146.27 402.822 146.266 400.076L146.266 159.133C146.269 156.85 147.026 154.631 148.42 152.822C149.815 151.012 151.768 149.712 153.977 149.122C154.846 148.891 155.741 148.775 156.64 148.777H319.794L348.895 174.715V400.076ZM150.513 149.122C148.794 150.17 147.374 151.64 146.388 153.393C145.402 155.146 144.884 157.123 144.883 159.133V160.859H146.266V159.133C146.269 156.85 147.026 154.631 148.42 152.822C149.815 151.012 151.768 149.712 153.977 149.122H150.513Z'
        fill='#1565D8'
      />
      <path
        d='M349.587 175.012H330.914C327.797 175.008 324.809 173.771 322.605 171.57C320.401 169.37 319.161 166.387 319.158 163.275V148.087C319.158 147.904 319.231 147.728 319.36 147.599C319.49 147.469 319.666 147.396 319.849 147.396C320.033 147.396 320.209 147.469 320.338 147.599C320.468 147.728 320.541 147.904 320.541 148.087V163.275C320.544 166.021 321.638 168.653 323.583 170.594C325.527 172.536 328.164 173.628 330.914 173.631H349.587C349.77 173.631 349.946 173.704 350.076 173.833C350.205 173.963 350.278 174.138 350.278 174.321C350.278 174.504 350.205 174.68 350.076 174.81C349.946 174.939 349.77 175.012 349.587 175.012Z'
        fill='#1565D8'
      />
      <path
        d='M326.448 220.922H168.771C167.303 220.922 165.896 220.34 164.859 219.304C163.821 218.269 163.238 216.864 163.238 215.399C163.238 213.934 163.821 212.529 164.859 211.494C165.896 210.458 167.303 209.876 168.771 209.876H326.448C327.915 209.876 329.322 210.458 330.36 211.494C331.398 212.529 331.98 213.934 331.98 215.399C331.98 216.864 331.398 218.269 330.36 219.304C329.322 220.34 327.915 220.922 326.448 220.922Z'
        fill='#C4DCFF'
      />
      <path
        d='M246.918 197.449H168.771C167.303 197.449 165.896 196.867 164.859 195.831C163.821 194.796 163.238 193.391 163.238 191.926C163.238 190.461 163.821 189.056 164.859 188.021C165.896 186.985 167.303 186.403 168.771 186.403H246.918C248.385 186.403 249.792 186.985 250.83 188.021C251.867 189.056 252.45 190.461 252.45 191.926C252.45 193.391 251.867 194.796 250.83 195.831C249.792 196.867 248.385 197.449 246.918 197.449Z'
        fill='#C4DCFF'
      />
      <path
        d='M326.448 267.177H168.771C167.303 267.177 165.896 266.596 164.859 265.56C163.821 264.524 163.238 263.119 163.238 261.654C163.238 260.19 163.821 258.785 164.859 257.749C165.896 256.713 167.303 256.131 168.771 256.131H326.448C327.915 256.131 329.322 256.713 330.36 257.749C331.398 258.785 331.98 260.19 331.98 261.654C331.98 263.119 331.398 264.524 330.36 265.56C329.322 266.596 327.915 267.177 326.448 267.177Z'
        fill='#C4DCFF'
      />
      <path
        d='M246.918 243.705H168.771C167.303 243.705 165.896 243.123 164.859 242.087C163.821 241.051 163.238 239.646 163.238 238.181C163.238 236.717 163.821 235.312 164.859 234.276C165.896 233.24 167.303 232.658 168.771 232.658H246.918C248.385 232.658 249.792 233.24 250.83 234.276C251.867 235.312 252.45 236.717 252.45 238.181C252.45 239.646 251.867 241.051 250.83 242.087C249.792 243.123 248.385 243.705 246.918 243.705Z'
        fill='#C4DCFF'
      />
      <path
        d='M320.858 373.151H174.246C171.221 373.148 168.321 371.946 166.181 369.811C164.042 367.675 162.839 364.78 162.835 361.76V303.768C162.839 300.748 164.042 297.852 166.181 295.717C168.321 293.581 171.221 292.38 174.246 292.376H320.858C323.884 292.38 326.784 293.581 328.923 295.717C331.062 297.852 332.266 300.748 332.269 303.768V361.76C332.266 364.78 331.063 367.675 328.923 369.811C326.784 371.946 323.884 373.148 320.858 373.151Z'
        fill='#1565D8'
      />
    </svg>
  );
};
